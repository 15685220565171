
#squadra {
  margin: 30px 0;
  font-size: 18px;
}

#squadra h4 {
  font-family: 'Signika Negative'sans-serif;
  font-size: 30px;
  text-transform: uppercase;
}

.highmail {
  color: #3c8362;
  font-weight: 800;
}

#rivenditori {
  margin-bottom: 50px;
}

#rivenditori .select {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
}

#rivenditori select {
  margin-left: 10px;
}

#rivenditori .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 801px) {

  #rivenditori .list .box-riv {
    flex-basis: 49%;
  }
}

@media (max-width: 800px) {

  #rivenditori .list .box-riv {
    flex-basis: 100%;
  }

}

.citta {
  font-style: italic;
  font-size: 20px;
  padding: 5px 0 5px 20px;
  margin: 25px 0 10px 0;
  border-bottom: 1px solid #d6d6d6;
}

.box-riv {
  margin-bottom: 15px;
  background-color: white;
  padding: 15px 45px;
  font-size: 16px;
}

.box-riv h3 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
}

.box-riv span {
  text-transform: uppercase;
  font-weight: 800;
}
