header {
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav>ul {
  list-style: none;
  font-size: 17px;
  background-color: rgba(76, 152, 96, 0.47);
  border-radius: 10px;
  padding: 0 10px 0 10px;
  display: flex;
}

@media (max-width: 970px) {
  header {
    flex-direction: column;
  }

  nav>ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.nav-line {
  line-height: 60px;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
}

.nav-line>a,
.nav-line>button,
.nav-dropdown {
  background-color: transparent;
  border: 0;
  display: inline-block;
  text-decoration: none;
  color: white;
  padding: 0 12px;
  height: 100%;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
}

.nav-line>a:hover {
  background-color: rgba(76, 152, 96, 0.47);
  text-decoration: none;
}

.nav-dropdown:hover {
  text-decoration: none;
  color: white;
  cursor: pointer;
  background-color: rgba(76, 152, 96, 0.47);
}

.dropdown {
  display: inline;
}

.visited {
  background-color: rgba(76, 152, 96, 0.47);
}

#nav-account ul {
  float: right;
  list-style: none;
  font-size: 14px;
  height: 59px;
}

#nav-account ul li {
  display: inline;
  line-height: 5px;
  text-align: center;
  text-shadow: 1px 1px 3px #5d5b5b;
}

#nav-account li a,
#nav-account li button {
  text-decoration: none;
  color: white;
  padding: 0 15px;
  align-content: center;
  transition: all 0.2s ease;
}

#nav-account li button {
  background: none;
  border: 0;
  height: 15px;
  text-shadow: 1px 1px 3px #5d5b5b;
}

#nav-account a:hover,
#nav-account li button:hover {
  text-shadow: none;
}

.contatti {
  width: 215px;
  height: 50px;
  background-image: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/icon-contatti.png);
  margin-top: -33px;
  margin-right: 15px;
  float: right;
}

.contatti h2 {
  display: none;
}

#searchbox {
  margin-top: 15px;
}

.search-right {
  float: right;
  margin-right: 10px;
}

#searchbox input {
  background-color: #313030;
  border-radius: 10px;
  border: none;
  width: 235px;
  color: #a3a2a2;
  padding-left: 10px;
}

#searchbox input:focus {
  border: none;
  box-shadow: none;

}

#searchbox button {
  background: none;
  border: none;
  line-height: 20px;
  margin-bottom: 10px;
}

#intestazione-h {
  opacity: 0.0;
}

.intestazione-h {
  font-family: 'Signika Negative'sans-serif;
  font-size: 45px;
  font-weight: 800;
  line-height: 25px;
  padding-left: 30px;
  margin-top: -13px;
}

.opacity {
  opacity: 0;
  filter: opacity(0);
}

.briciole {
  font-size: 14px;
}

.briciole a {
  color: inherit;
}
