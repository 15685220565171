#contacts {
  margin-bottom: 30px;
}

#contacts h5 {
  max-width: 550px;
}

#contacts a {
  color: black;
  display: block;
  padding: 5px 0;
}

.indirizzo {
  background-color: #f3f4fa;
  font-size: 21px;
  line-height: 2rem;
  padding: 10px 20px;
  border-radius: 8px;
  border-left: 5px solid rgb(76, 152, 96);
}

.indirizzo h5 {
  font-family: 'Signika Negative'sans-serif;
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
  color: #3a3a3c;
  padding-top: 20px;
}

.mail {
  font-size: 20px;
  line-height: 20px;
  padding-left: 40px;
  display: flex;
  flex-wrap: wrap;
}

@media (min-width:900px) {
  .mail>div {
    width: 50%;
  }
}

@media (max-width:899px) {
  .mail>div {
    width: 100%;
  }
}

.mail h5 {
  padding-top: 20px;
  font-size: 26px;
  color: #90c16f;
  font-family: "Calluna Sans"sans-serif;
  line-height: 2.8rem;
  margin-bottom: 0;
}

.mail .role {
  display: block;
  font-style: italic;
  font-size: 20px;
}
