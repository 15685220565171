#badge {
  margin-left: 10px;
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/badge.png) no-repeat center;
  width: 306px;
  height: 239px;
}

#badge span {
  opacity: 0;
}

#frasi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 60px 0 120px 0;
}

.frase {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  max-width: 300px;
  margin-left: 10px;
}

.b {
  display: inline-block;
  width: 63px;
  height: 100px;
}

#b-leader {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/b-leader.png) no-repeat center;
}

#b-rete {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/b-rete.png) no-repeat center;
}

#b-prodotti {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/b-prodotti.png) no-repeat center;
}

#b-ricerca {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/b-ricerca.png) no-repeat center;
}

#b-competitivi {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/b-competitivi.png) no-repeat center;
}

.frase h4 {
  display: inline-block;
  border: solid 6px rgba(42, 108, 36, 0.5);
  border-radius: 6px;
  padding: 18px;
  margin-left: 15px;
  width: 72%;
  font-family: "Calluna sans"sans-serif;
  font-weight: 300;
  color: white;
  font-size: 17px;
}

.frase-nera {
  color: #3a3a3c;
  font-weight: 500;
}

.home_servizi {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
}

.home_prodotti {
  position: relative;
}

.riga_servizio {
  display: flex;
  margin: 30px 0;
}

@media (min-width: 1140px) {
  .riga_servizio {
    width: 50%;
  }

  .s-icona {
    display: block;
  }

  .s-gestione a,
  .s-vendita a,
  .s-tesoro a,
  .s-customer a {
    margin-left: 50%;
  }
}

@media (max-width: 1139px) {
  .riga_servizio {
    width: 100%;
    justify-content: center;
  }

  .riga_servizio>div {
    width: 100%;
  }

  .riga_servizio h5 {
    text-align: center;
  }

  .s-icona {
    display: none;
  }
}

.riga_servizio>div {
  margin: 0 20px;
}

.titolone {
  width: 100%;
  height: 91px;
  position: absolute;
  top: -94px;
  padding-bottom: 100px;
}

#servizi {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/t-servizi.png) no-repeat center;
}

.s-icona {
  width: 115px;
  height: 66%;
}

#s-gestione {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/s-gestione.png) no-repeat center;
}

#s-vendita {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/s-vendita.png) no-repeat center;
}

#s-tesoro {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/s-tesoro.png) no-repeat center;
}

#s-customer {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/s-customer.png) no-repeat center;
}

.s-gestione {
  font-size: 18px;
}

.s-vendita {
  font-size: 18px;
}

.s-tesoro {
  font-size: 18px;
}

.s-customer {
  font-size: 18px;
}

.s-gestione h5,
.s-tesoro h5,
.s-vendita h5,
.s-customer h5 {
  font-family: 'Signika'sans-serif;
  font-size: 30px;
  font-weight: 800;
  color: #3c8362;
}

.s-gestione a,
.s-vendita a,
.s-tesoro a,
.s-customer a {
  text-align: center;
  width: 150px;
  margin-bottom: -50px;
  font-weight: 800;
}

.blocco {
  margin: 20px auto;
  padding: 25px;
  border: solid 1px #dedede;
}

.blocco p {
  font-size: 20px;
  font-weight: 800;
}


.grigino {
  background-color: #f3f4fa;
}

#prodotti {
  background: url(https://res.cloudinary.com/asantalu/image/upload/v1583736106/t-prodotti.png) no-repeat center;
}

.prodotti {
  margin-top: 80px;
}


.btn-centro {
  text-align: center;
  margin-bottom: 60px;
}
